// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cta-js": () => import("../src/pages/cta.js" /* webpackChunkName: "component---src-pages-cta-js" */),
  "component---src-pages-employees-js": () => import("../src/pages/employees.js" /* webpackChunkName: "component---src-pages-employees-js" */),
  "component---src-pages-employers-js": () => import("../src/pages/employers.js" /* webpackChunkName: "component---src-pages-employers-js" */),
  "component---src-pages-hero-js": () => import("../src/pages/hero.js" /* webpackChunkName: "component---src-pages-hero-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-workpal-features-js": () => import("../src/pages/workpal_features.js" /* webpackChunkName: "component---src-pages-workpal-features-js" */)
}

